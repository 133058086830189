<section>
  <div class="section-footer">
  <div class="footer-container">
    <div class="footer-container-left">
      <img src="assets/img/footer/coding-logo.png" alt="" />
      <a
        class="hover-underline-animation"
        (click)="navigateToImprint()"
        >{{ 'footer.imprint' | translate}}</a
      >
    </div>
    <span>© Lukas Nolting 2024</span>
    <div class="footer-container-right">
      <a href="https://github.com/LukasNolting" target="_blank"
        ><img src="assets/img/footer/footer-github.svg" alt=""
      /></a>
      <a href="mailto:info@lukas-nolting.de"
        ><img src="assets/img/footer/footer-email.svg" alt=""
      /></a>
      <a href="https://www.linkedin.com/in/lukas-nolting-1aa559296/" target="_blank"
        ><img src="assets/img/footer/footer-linkedin.svg" alt=""
      /></a>
    </div>
  </div>
  </div>
</section>