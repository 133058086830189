<section>
  <div class="section-portfolio">
    <div class="portfolio-header">
      
            <div class="line-left">
        <div class="coloredLineLeft"></div>
      </div>
      

      <h1>Portfolio</h1>

      <div class="line">
        <div class="coloredLineLeft"></div>
      </div>
    </div>
    <div class="portfolio-header-text">
      <span>
        {{ "portfolio.description" | translate }}
      </span>
    </div>

    <div class="projects-container">
      <div
        *ngFor="let projects of projects"
        class="project-{{ projects.sequence }}"
      >
        <img
          src="{{ projects.image }}"
          alt=""
          data-aos="flip-left"
          data-aos-duration="1500"
          class="project-image"
        />
        <div
          data-aos="flip-left"
          data-aos-duration="1500"
          [ngStyle]="{
            'align-items': projects.sequence == 'regular' ? 'start' : 'end'
          }"
          [ngStyle]="{
            'text-align': projects.sequence == 'regular' ? 'start' : 'end'
          }"
          class="project-description"
        >
          <div class="project-overview">
            <span>{{ projects.title }}</span>
            <span>{{ projects.languages }}</span>
            <span>{{ projects.translatedDescription | translate }}</span>
          </div>
          <div
            class="project-description-buttons"
            [ngStyle]="{
              'justify-content':
                projects.sequence == 'regular' ? 'flex-start' : 'flex-end'
            }"
          >
            <a href="{{ projects.directLink }}" target="_blank"
              ><button>Live test</button></a
            >

            <a href="{{ projects.gitHubLink }}" target="_blank"
              ><button>Github</button></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
