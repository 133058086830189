<section>
  <div class="section-skills">
  <div class="skills">
    <div *ngFor="let skill of skills">
      <div class="skill-box" data-aos="fade-right">
        <img src="{{ skill.img }}" alt="" class="jump" />
        <span>{{ skill.name }}</span>
      </div>
    </div>
  </div>

  <div class="my-skills-container">
    <div class="my-skills-header">
      <h1>{{ "skills.title" | translate }}</h1>

      <div class="my-skills-text">
        {{ "skills.description" | translate }}
      </div>

      <a href="#contact" class="screen-button"><button>{{ "skills.getintouch" | translate }}</button></a>
    </div>
  </div>
</div>
<!-- <img src="assets/img/skills/shadow-skills.png" class="shadow-skills" /> -->
</section>
<div class="my-skills-container-mobile">
  <div class="my-skills-header-mobile">
    
    <a href="#contact"><button class="mobile-button">Get in Touch</button></a>
  </div>
  
</div>
