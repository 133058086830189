<div class="navigation-top">
  <div class="header">
    <!-- <span>Lukas Nolting</span> -->
    <a (click)="navigateToHome()"
      ><img
        src="../assets/img/navigation/coding-logo.png"
        alt=""
        class="nav-logo"
        (click)="clickEventLogo()"
    /></a>
    <ul class="list-screen">
      <a
        (click)="navigateTo('about-me', 20)"
        class="hover-underline-animation"
        >{{ "navigation.about-me" | translate }}</a
      >
      <a
        (click)="navigateTo('skills', 20)"
        class="hover-underline-animation"
        >{{ "navigation.skills" | translate }}</a
      >
      <a
        (click)="navigateTo('portfolio', 20)"
        class="hover-underline-animation"
        >{{ "navigation.portfolio" | translate }}</a
      >
      <div style="padding: 2px 4px">
        <a
          class="hover-underline-animation"
          style="padding-right: 3px"
          (click)="changeLanguage('de')"
          [class.activeLanguage]="langDe"
          >DE</a
        >
        <span>&#124;</span>
        <a
          class="hover-underline-animation"
          style="padding-left: 3px"
          (click)="changeLanguage('en')"
          [class.activeLanguage]="langEn"
          >EN</a
        >
      </div>
    </ul>
    <div class="content second d-none" (click)="clickEvent()">
      <div class="burger" [ngClass]="status ? 'active' : ''">
        <span></span>
      </div>
    </div>

    <div
      class="nav-mobile d-none"
      [ngClass]="status ? 'slide-in' : 'slide-out'"
    >
      <ul>
        <a
          href="#about-me"
          class="hover-underline-animation"
          (click)="clickEventLogo()"
          >{{ "navigation.about-me" | translate }}</a
        >
        <a
          href="#skills"
          class="hover-underline-animation"
          (click)="clickEventLogo()"
          >{{ "navigation.skills" | translate }}</a
        >
        <a
          href="#portfolio"
          class="hover-underline-animation"
          (click)="clickEventLogo()"
          >{{ "navigation.portfolio" | translate }}</a
        >
        <a
          href="#contact"
          class="hover-underline-animation"
          (click)="clickEventLogo()"
          >{{ "navigation.contact" | translate }}</a
        >
        <a
          routerLink="imprint"
          class="hover-underline-animation"
          (click)="clickEventLogo()"
          (click)="navigateToImprint()"
          >{{ "navigation.imprint" | translate }}</a
        >
        <a
          routerLink="imprint"
          class="hover-underline-animation"
          (click)="clickEventLogo()"
          (click)="navigateToPrivacy()"
          >{{ "navigation.privacy" | translate }}</a
        >
        <div style="padding: 2px 10px">
          <a
            class="hover-underline-animation"
            style="padding-right: 10px"
            (click)="changeLanguage('de')"
            [class.activeLanguage]="langDe"
            >DE</a
          >
          <span>&#124;</span>
          <a
            class="hover-underline-animation"
            style="padding-left: 10px"
            (click)="changeLanguage('en')"
            [class.activeLanguage]="langEn"
            >EN</a
          >
        </div>
      </ul>
    </div>
  </div>
</div>