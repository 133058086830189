<!-- <img
  src="assets/img/about-me/shadow-about.png"
  alt=""
  class="shadow-about"
/> -->
<section>
  <div class="about-section-head">
  <div class="about-section">
    <h1>{{ "about-me.title" | translate }}</h1>
    <p>
      {{ "about-me.description" | translate }}
    </p>
    <div class="about-bulb" data-aos="fade-right">
      <img src="assets/img/about-me/about-bulb.png" alt="" data-aos="flip-up" />
      <p>
        {{ "about-me.bulp" | translate }}
      </p>
    </div>
    <div class="about-puzzle" data-aos="fade-right">
      <img src="assets/img/about-me/about-puzzle.png" alt="" data-aos="flip-up" />
      <p>
        {{ "about-me.puzzle" | translate }}
      </p>
    </div>
  </div>

  <div class="character-container">
    <img src="assets/img/intro/me_sp.png" alt="">
  </div>
  </div>
</section>
